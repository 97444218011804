import React from 'react'

const SeventhSection = () => {

    return (
        <div className='py-12'>

            <div className='mt-12 container mx-auto pb-12'>

                <div className='gap-10 md:flex items-center justify-start'>
                    <img
                        data-aos="fade-right"
                        src="/images/icons/coffee.png"
                        className='w-[70px] lg:w-[110px]'
                    />
                    <div>
                        <p data-aos="fade-up" className="text-left text-blue-primary font-black text-2xl lg:text-3xl">
                            SERVIZI ACCESSORI FORNITI
                        </p>
                        <p data-aos="fade-right" className="mt-5 text-left text-black font-medium text-xl lg:text-2xl">
                            All’interno di <span className='font-bold'>Villa IRIS</span> è presente, un <span className='font-bold'>BAR RISTORO</span> aperto al pubblico
                            dal lunedì al sabato dalle 8.00 alle 18.30, domenica e festivi dalle 13.30 alle 18.30
                        </p>
                    </div>

                </div>

            </div>
        </div>

    )
}

export default SeventhSection