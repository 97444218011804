import React, { useEffect, useState } from 'react'
import { AMMINISTRAZIONE_TRASPARENTE_URL } from '../../constants'
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("location", location)
  }, [location]);

  return (

    <div className="flex w-full">
      <img
        loading="lazy"
        // src={"/images/header.png"}
        src={location.pathname === AMMINISTRAZIONE_TRASPARENTE_URL || location.pathname === "/privacy-policy" || location.pathname === "/cookie-policy" || location.pathname === "/whistleblowing" ? "/images/header_two.jpg" : "/images/header.png"}
        className="w-full"
      />
      {/* Header Logo */}
      <div className="absolute top-10 left-8 xl:left-24 z-20">
        <Link to={"/"} className="cursor-pointer">
          <img
            loading="lazy"
            src="/images/villairis.png"
            className="w-1/3 lg:w-1/2 xl:w-4/6"
          />
        </Link>
      </div>
    </div>
  )
}

export default Header