import React from 'react'
import { Link } from 'react-router-dom'
import { AMMINISTRAZIONE_TRASPARENTE_URL } from '../../constants'

const Footer = () => {
    return (
        <div id="footer" className='relative flex-shrink-0 bg-gray-bg'>
            {/* <footer className='absolute bottom-0 z-[-1] flex-shrink-0'> */}
            <div className="container mx-auto flex flex-col items-center justify-between px-4 lg:px-6 mb-0">
                <div className='flex items-center justify-center py-8'>
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-12">
                        <div className="flex items-start gap-x-4">
                            <div className="w-full">
                                <Link to={"/"} className="">
                                    <img src="/images/villairis.png" alt="" className="w-full" />
                                </Link>
                            </div>
                        </div>

                        <div className="md:col-span-4 w-full flex flex-col md:flex-row lg:flex-col space-x-0 md:space-x-8 lg:space-x-0 items-start text-blue-primary">
                            <div className='flex flex-col items-start mb-4'>
                                <p className='mb-0 text-sm font-bold'>Villa Iris s.r.l.</p>
                                <p className='mb-0 text-sm font-normal'>Sede Legale: Piazza Gozzano 1 – 10132 Torino (TO)</p>
                                <p className='mb-0 text-sm font-normal'>Sede Operativa: Via Cesare Pavese 12 – 10044 Pianezza (TO)</p>
                                <p className='mb-0 text-sm font-normal'>P.IVA: 03880520014</p>
                                <p className='mb-1 text-sm font-normal'>Capitale Sociale: 25.822,84 i.v.</p>
                                <p className='mb-0 text-sm font-normal'>REA: TO 594994</p>
                                <p className='mb-0 text-sm font-normal'>PEC: villairis@legalmail.it</p>
                            </div>

                            <div className='flex flex-wrap'>
                                <p className='mb-0 text-sm font-normal'>Villa Iris s.r.l. è accreditata con il SSR e certificata UNI EN ISO 9001:2015 per l’erogazione di servizi di diagnosi e cura in regime di degenza ordinaria e ambulatoriale</p>
                                <div className='flex flex-wrap gap-x-2 font-bold'>
                                    <Link to={AMMINISTRAZIONE_TRASPARENTE_URL} className='mb-0 underline hover:cursor-pointer'>AMMINISTRAZIONE TRASPARENTE</Link>
                                    <p className='mb-0'>|</p>
                                    <Link to={"/privacy-policy"} className='mb-0 underline hover:cursor-pointer'>PRIVACY POLICY</Link>
                                    <p className='mb-0'>|</p>
                                    <Link to={"/cookie-policy"} className='mb-0 underline hover:cursor-pointer'>COOKIE POLICY</Link>
                                    <p className='mb-0'>|</p>
                                    <Link to={"/whistleblowing"} className='mb-0 underline hover:cursor-pointer'>WHISTLEBLOWING</Link>
                                    <p className='mb-0'>|</p>
                                    <Link to={"https://poliambulatorio.s3.us-west-2.amazonaws.com/Carta_Servizi_Villa+Iris_2024.pdf"} className='mb-0 underline hover:cursor-pointer'>CARTA DEI SERVIZI</Link>
                                    <p className='mb-0'>|</p>
                                    <Link to={"https://kaffeinabucket.s3.eu-west-3.amazonaws.com/camerlengo/Villairis-Politica+Qualit%C3%A0_17-05-2024+(1).pdf"} className='mb-0 underline hover:cursor-pointer'>POLITICA DELLA QUALITÀ</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer