import React from 'react'

const SixthSection = () => {

    const dataLeft = [
        {
            icon: "/images/icon-container/01.png",
            title: "DIAGNOSTICA PER IMMAGINI",
            text: [
                "Radiografie",
                "Risonanza magnetica",
                "Tac",
                "Densitometria ossea Lombare/Femorale",
                "Ecocolordoppler Vascolari",
                "Ecografie",
            ]
        },
        {
            icon: "/images/icon-container/02.png",
            title: "CARDIOLOGIA",
            text: [
                "Visite Specialistiche",
                "Elettrocardiogramma",
                "Elettrocardiogramma sotto sforzo con cicloergometro",
                "Elettrocardiogramma dinamico Holter",
                "Ecocardiogramma",
                "Monitoraggio pressorio",
            ]
        },
        {
            icon: "/images/icon-container/03.png",
            title: "LABORATORIO ANALISI CLINICHE",
            text: [
                "Chimica Clinica",
                "Ematologia",
                "Microbiologia",
                "Sieroimmunologia",
            ]
        },
        {
            icon: "/images/icon-container/04.png",
            title: "ORTOPEDIA",
            text: [
                "Visite specialistiche",
                "Infiltrazioni Endoarticolari/Mesoterapia/Artrocentesi/Bendaggio",
                "Infiltrazioni Ecoguidate",
                "Onde d’urto focalizzate per uso ortopedico",
            ]
        },
        {
            icon: "/images/icon-container/05.png",
            title: "OCULISTICA",
            text: [
                "Visite Specialistiche",
                "Fundus Oculi -Tonometria",
                "Test di Shimmer -Specillazione",
                "Campo Visivo Computerizzato",
                "Valutazione Ortottica -Riabilitazione Ortottica",
            ]
        },
        {
            icon: "/images/icon-container/06.png",
            title: "NEUROLOGIA:",
            text: [
                "Visite Specialistiche",
                "Elettromiografia",
            ]
        },
        {
            icon: "/images/icon-container/07.png",
            title: "PNEUMOLOGIA",
            text: [
                "Visite Specialistiche",
                "Spirometria semplice"
            ]
        },
        {
            icon: "/images/icon-container/08.png",
            title: "DERMATOLOGIA",
            text: [
                "Visite Specialistiche",
                "Mappatura nei",
                "Asportazione Chirurgica nei",
            ]
        },
        {
            icon: "/images/icon-container/09.png",
            title: "CHIROPRATICA",
            text: []
        }
    ];

    const dataRight = [

        {
            icon: "/images/icon-container/10.png",
            title: "CHIRURGIA AMBULATORIALE/PROCTOLOGIA",
            text: [
                "Visite Chirurgiche",
                "Interventi Ambulatoriali",
                "Esame istologico",
            ]
        },

        {
            icon: "/images/icon-container/11.png",
            title: "NEUROCHIRURGIA",
            text: [
                "Visite Specialistiche per la Colonna Vertebrale",
            ]
        },
        {
            icon: "/images/icon-container/12.png",
            title: "UROLOGIA/ANDROLOGIA",
            text: [
                "Visite Specialistiche",
                "Uroflussometria",
            ]
        },
        {
            icon: "/images/icon-container/13.png",
            title: "FONIATRIA",
            text: [
                "Visite Specialistiche",
                "Laringoscopia a fibre Ottiche",
            ]
        },
        {
            icon: "/images/icon-container/14.png",
            title: "OTORINOLARINGOIATRA",
            text: [
                "Visite Specialistiche",
                "Laringoscopia a Fibre Ottiche",
                "Esame Audiometrico Tonale",
            ]
        },
        {
            icon: "/images/icon-container/15.png",
            title: "EMATOLOGIA",
            text: [
                "Visite Specialistiche",
            ]
        },
        {
            icon: "/images/icon-container/16.png",
            title: "GERIATRIA",
            text: [
                "Visite Specialistiche",
            ]
        },
        {
            icon: "/images/icon-container/17.png",
            title: "ENDOCRINOLOGIA",
            text: [
                "Visite Specialistiche",
            ]
        },
        {
            icon: "/images/icon-container/18.png",
            title: "RECUPERO E RIEDUCAZIONE FUNZIONALE",
            text: [
                "Rieducazione Posturale",
                "Tecarterapia",
                "Terapie fisiche strumentali",
                "Trattamenti chiropratici",
                "Massoterapia",
                "Idrokinesiterapia",
            ]
        },
        {
            icon: "/images/icon-container/19.png",
            title: "ODONTOIATRIA/ORTODONZIA",
            text: [
                "Estrazioni",
                "Otturazioni",
                "Devitalizzazioni",
                "Protesi",
                "Ablazione tartaro",
                "Apparecchi ortodontici fissi/mobili",
                "Bite",
            ]
        },
        {
            icon: "/images/icon-container/20.png",
            title: "CONSULENZA NUTRIZIONALE",
            text: [
                "Anamnesi",
                "Rilevazione di Misure Antropometriche",
                "Analisi Impedenziometrica",
                "Piano dietetico personalizzato",
            ]
        },
    ];

    return (
        <div className='mt-12  bg-[#8bceda] py-12'>

            <div className='container mx-auto'>
                <p data-aos="fade-right" className="text-left text-white font-medium text-2xl lg:text-3xl">
                    ELENCO <span className='font-bold'>PRESTAZIONI SPECIALISTICHE</span>
                </p>

                <div className='mt-12 grid lg:grid-cols-2 content-start gap-5'>
                    <div className='space-y-5'>
                        {
                            dataLeft.map((item, index) => (
                                <IconContainer key={index} item={item} />
                            ))
                        }
                    </div>

                    <div className='space-y-5'>
                        {
                            dataRight.map((item, index) => (
                                <IconContainer key={index} item={item} />
                            ))
                        }
                    </div>

                </div>

            </div>
        </div>

    )
}

const IconContainer = ({ item }) => {
    return (
        <div className='space-x-3 flex items-start'>
            <img data-aos="fade-right" src={item.icon} className='flex-shrink-0 w-[50px]' />
            <div className=''>
                <p data-aos="fade-right" className='text-xl font-bold text-[#004280]'>
                    {item.title}
                </p>
                {
                    item.otherText && item.otherText.map((o, oi) => (
                        <p data-aos="fade-right" key={oi} className="font-medium text-lg" >
                            {o}
                        </p>
                    ))
                }

                <div>
                    {
                        item.text.map((t, i) => (
                            <div data-aos="fade-right" className='flex gap-2'>
                                <p className="font-medium text-lg" key={i}>
                                    •
                                </p>
                                <p className="font-medium text-lg" key={i}>
                                    {t}
                                </p>
                            </div>
                        ))
                    }
                </div>

            </div>

        </div>
    )
}

export default SixthSection