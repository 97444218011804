import React from 'react'

import { FaArrowRight } from "react-icons/fa6";

const FifthSection = () => {
    return (
        <div className='mt-12 container mx-auto pb-12'>
            <div>

                <img
                    data-aos="fade-right"
                    src="/images/logo_190.png"
                    className=''
                />

                <p data-aos="fade-right" className="text-cls mt-8">
                    <span className='font-bold'>Villa Iris Formazione</span> nasce nel contesto sanitario della {" "}
                    <span className='font-bold'>Casa di Cura</span> e del {" "}
                    <span className='font-bold'>Poliambulatorio</span> con l’intento di offrire occasioni per l’aggiornamento continuo e la formazione progettando per il personale sanitario interno, soddisfacendo il fabbisogno formativo e garantendo un aggiornamento continuo e qualitativo alla struttura.
                </p>
            </div>

            <div className="w-11/12 flex flex-col-reverse justify-between gap-8 lg:gap-16 lg:flex-row lg:mb-0 mt-12">
                {/* <!-- Content Left --> */}
                <div data-aos="fade-right" data-aos-duration="1500" className="flex flex-col justify-between gap-4 lg:w-1/2">
                    <div className="flex flex-col justify-start gap-4">
                        <h1 className="text-blue-primary text-4xl font-bold text-right">
                            VILLAIRIS <span className='text-pink-primary text-right'>FORMAZIONE</span>
                        </h1>
                        <p className="!leading-10 w-full mx-auto text-right text-blue-primary font-normal text-2xl underline underline-offset-8 lg:mt-0 lg:mx-0">
                            Via Cesare Pavese 12 – 10044 Pianezza (TO)
                        </p>
                    </div>

                    <div className="mt-8">
                        <p className="text-3xl font-bold text-gray-text text-right">CONTATTI</p>

                        <div className="flex flex-col justify-end">

                            {/* <!-- Contact Info --> */}
                            <div className="flex flex-col justify-start mt-4 space-y-6 text-xl md:space-y-0 md:space-x-4">
                                <p className="text-right text-2xl font-bold text-pink-primary">Informazioni</p>
                                <a href="mailto:segreteria@formazione-sanitaria.it" className="text-2xl font-medium text-blue-primary text-right no-underline">segreteria@formazione-sanitaria.it</a>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-end mb-12">
                        <a href="tel:011.966.37.00" className="flex items-center justify-between space-x-4 px-3 py-2 rounded-lg border-2 border-green-primary overflow-hidden cursor-pointer">
                            <img loading="lazy" src="/images/icons/phone.png" alt="phone-icon" className="h-12" />
                            <p className="no-underline text-green-primary text-2xl font-black">011.966.37.00</p>
                        </a>
                    </div>
                </div>

                {/* <!-- Content Right --> */}
                <div className="relative flex flex-col justify-start items-center gap-4 lg:w-1/2">
                    <div className="flex space-x-4 w-full">
                        {/* Box 1 */}
                        <div data-aos="fade-up" data-aos-duration="1500" className="w-1/2 bg-gray-bg aspect-square">
                            <div className="w-full bg-cover bg-center" style={{ backgroundImage: 'url("/images/formazione.png")' }}>
                                <img src="/images/formazione.png" alt="" className="w-full opacity-0" />
                            </div>
                        </div>
                        {/* Box 2 */}
                        <div data-aos="fade-up" data-aos-duration="1500" className="w-1/2 bg-blue-primary aspect-square p-4 pb-12">
                            <img loading="lazy" src="/images/icons/clock.png" alt="clock-icon" className="h-12" />
                            {/* <FaRegClock className="text-5xl text-blue-primary" /> */}
                            <p className="text-left text-xl font-bold text-white mt-4">VILLAIRIS</p>
                            <p className="text-left text-xl font-bold text-white mb-2">FORMAZIONE</p>
                            <p className="text-left text-xl text-white">LUN / VEN</p>
                            <div className="flex items-center space-x-2">
                                <p className="text-left text-xl text-white">10.00</p>
                                <FaArrowRight className="text-3xl text-white" />
                                <p className="text-left text-xl text-white">17.00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FifthSection