import React from 'react'

import { FaArrowRight, FaRegClock } from "react-icons/fa6";

const SecondSection = () => {
    return (
        <>
            <div className='mt-8 container mx-auto pb-12 border-b-2 border-blue-secondary'>
                <div className='space-y-3'>
                    <p data-aos="fade-right" className="text-cls">
                        <span className='font-bold'>Il Poliambulatorio Villa IRIS è accreditato con il SSR,</span> eroga prestazioni in regime
                        convenzionato e in forma privata, offrendo la possibilità di usufruire di servizi sanitari
                        in ambienti nuovi, accoglienti, climatizzati e dotati di <span className='font-bold'>apparecchiature all’avanguardia.</span>
                    </p>
                </div>
            </div>

            <div className="w-11/12 flex flex-col-reverse justify-between gap-8 lg:gap-16 lg:flex-row lg:mb-0 mt-12">
                {/* <!-- Content Left --> */}
                <div data-aos="fade-right" data-aos-duration="1500" className="flex flex-col justify-between gap-4 lg:w-1/2">
                    <div className="flex flex-col justify-start gap-4">
                        <h1 className="text-blue-primary text-4xl font-bold text-right">
                            POLIAMBULATORIO <span className='text-pink-primary text-right'>VILLAIRIS</span>
                        </h1>
                        <p className="!leading-10 w-full mx-auto text-right text-blue-primary font-normal text-2xl underline underline-offset-8 lg:mt-0 lg:mx-0">
                            Via Cesare Pavese 12 – 10044 Pianezza (TO)
                        </p>
                    </div>

                    <div className="mt-8">
                        <p className="text-3xl font-bold text-gray-text text-right">CONTATTI</p>

                        <div className="flex flex-col justify-end">

                            {/* <!-- Contact Info --> */}
                            <div className="flex flex-col justify-start mt-4 space-y-6 text-xl md:space-y-0 md:space-x-4">
                                <p className="text-right text-2xl font-bold text-pink-primary">Prenotazioni</p>
                                <a href="mailto:prenotazioni@villairis.srl" className="text-2xl font-medium text-blue-primary text-right no-underline">prenotazioni@villairis.srl</a>
                            </div>
                            {/* <!-- Contact Info --> */}
                            <div className="flex flex-col justify-start mt-4 space-y-6 text-xl md:space-y-0 md:space-x-4">
                                <p className="text-right text-2xl font-bold text-pink-primary">Servizio di Radiologia</p>
                                <a href="mailto:radiologia@villairis.srl" className="text-2xl font-medium text-blue-primary text-right no-underline">radiologia@villairis.srl</a>
                            </div>
                            {/* <!-- Contact Info --> */}
                            <div className="flex flex-col justify-start mt-4 space-y-6 text-xl md:space-y-0 md:space-x-4">
                                <p className="text-right text-2xl font-bold text-pink-primary">Servizio di Fisioterapia</p>
                                <a href="mailto:fisioterapia@villairis.srl" className="text-2xl font-medium text-blue-primary text-right no-underline">fisioterapia@villairis.srl</a>
                            </div>
                            {/* <!-- Contact Info --> */}
                            <div className="flex flex-col justify-start mt-4 space-y-6 text-xl md:space-y-0 md:space-x-4">
                                <p className="text-right text-2xl font-bold text-pink-primary">Servizio di Logopedia</p>
                                <a href="mailto:prenotazioni.logo@villairis.srl" className="text-2xl font-medium text-blue-primary text-right no-underline">prenotazioni.logo@villairis.srl</a>
                            </div>

                        </div>
                    </div>

                    <div className="flex items-center justify-end mb-12">
                        <a href="tel:011.968.22.82" className="flex items-center justify-between space-x-4 px-3 py-2 rounded-lg border-2 border-green-primary overflow-hidden cursor-pointer">
                            <img loading="lazy" src="/images/icons/phone.png" alt="phone-icon" className="h-12" />
                            <p className="no-underline text-green-primary text-2xl font-black">011.968.22.82</p>
                        </a>
                    </div>
                </div>

                {/* <!-- Content Right --> */}
                <div className="relative flex flex-col justify-start items-center gap-4 lg:w-1/2">
                    <div className="flex space-x-4 w-full">
                        {/* Box 1 */}
                        <div data-aos="fade-up" data-aos-duration="1500" className="w-1/2 bg-blue-primary aspect-square p-4 pb-12">
                            <img loading="lazy" src="/images/icons/calendar.png" alt="clock-icon" className="h-12" />
                            {/* <FaRegClock className="text-5xl text-white" /> */}
                            <p className="text-left text-xl font-bold text-white mt-4">LE PRENOTAZIONI</p>
                            <p className="text-left text-xl text-white mb-2">possono avvenire:</p>
                            <div className="flex items-center space-x-2">
                                <FaArrowRight className="text-3xl text-[#9cd1df]" />
                                <div>
                                    <p className="text-left text-xl text-white">online</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-2">
                                <FaArrowRight className="text-3xl text-[#9cd1df]" />
                                <div>
                                    <p className="text-left text-xl text-white">in ambulatorio</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-2">
                                <FaArrowRight className="text-3xl text-[#9cd1df]" />
                                <div>
                                    <p className="text-left text-xl text-white">al telefono</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-2">
                                <FaArrowRight className="text-3xl text-[#9cd1df]" />
                                <div>
                                    <p className="text-left text-xl text-white">via mail</p>
                                </div>
                            </div>
                        </div>
                        {/* Box 2 */}
                        <div data-aos="fade-up" data-aos-duration="1500" className="w-1/2 bg-pink-primary aspect-square p-4 pb-12">
                            <img loading="lazy" src="/images/icons/clock.png" alt="clock-icon" className="h-12" />
                            {/* <FaRegClock className="text-5xl text-blue-primary" /> */}
                            <p className="text-left text-xl font-bold text-white mt-4">POLIAMBULATORIO</p>
                            <p className="text-left text-xl font-bold text-white mb-2">VILLAIRIS</p>
                            <p className="text-left text-xl text-white">LUN / VEN</p>
                            <div className="flex items-center space-x-2">
                                <p className="text-left text-xl text-white">07.30</p>
                                <FaArrowRight className="text-3xl text-white" />
                                <p className="text-left text-xl text-white">19.30</p>
                            </div>
                            <p className="text-left text-xl text-white">SAB</p>
                            <div className="flex items-center space-x-2">
                                <p className="text-left text-xl text-white">07.30</p>
                                <FaArrowRight className="text-3xl text-white" />
                                <p className="text-left text-xl text-white">13.30</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex space-x-4 w-full">
                        {/* Box 1 */}
                        <div data-aos="fade-up" data-aos-duration="1500" className="w-1/2 bg-blue-primary aspect-square p-4 pb-12">
                            <img loading="lazy" src="/images/icons/clock.png" alt="clock-icon" className="h-12" />
                            {/* <FaRegClock className="text-5xl text-white" /> */}
                            <p className="text-left text-xl font-bold text-white mt-4">ORARIO PRENOTAZIONI</p>
                            <p className="text-left text-xl text-white mb-2">LUN / VEN</p>
                            <p className="text-left text-xl text-white">telefonicamente o in persona</p>
                            <div className="flex items-center space-x-2">
                                <p className="text-left text-xl text-white">10.00</p>
                                <FaArrowRight className="text-3xl text-white" />
                                <p className="text-left text-xl text-white">19.00</p>
                            </div>
                            <br></br>
                            <p className="text-left text-xl text-white">SAB</p>
                            <p className="text-left text-xl text-white">telefonicamente o in persona</p>
                            <div className="flex items-center space-x-2">
                                <p className="text-left text-xl text-white">10.00</p>
                                <FaArrowRight className="text-3xl text-white" />
                                <p className="text-left text-xl text-white">13.00</p>
                            </div>
                        </div>
                        {/* Box 2 */}
                        <div data-aos="fade-up" data-aos-duration="1500" className="w-1/2 bg-gray-bg aspect-square">
                            <div className="w-full bg-cover bg-center" style={{ backgroundImage: 'url("/images/piscina.png")' }}>
                                <img src="/images/piscina.png" alt="" className="w-full opacity-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecondSection