import React, { useEffect } from "react";

import { FaArrowRight, FaRegClock } from "react-icons/fa6";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
    return (


        <div className="container mx-auto flex flex-col items-center justify-between px-4 lg:px-6 mb-0 z-30">

            <div className="w-11/12 flex flex-col my-12">
                <h1 className="w-full mx-auto text-left text-blue-primary font-normal text-3xl lg:text-4xl mt-6 mb-8 lg:mx-0">
                    Cookie <span className="font-bold">Policy</span>
                </h1>


                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">
                    Uso dei cookie
                </p>

                <p className="text-base font-normal text-black mb-4">
                    Villa Iris o il “Sito” utilizza i Cookie per rendere i propri servizi semplici e efficienti per l’utenza che visiona le pagine del Sito.<br />
                    Gli utenti che visionano il Sito, vedranno inserite delle quantità minime di informazioni nei dispositivi in uso, che siano computer e periferiche mobili, in piccoli file di testo denominati “cookie” salvati nelle directory utilizzate dal browser web dell’Utente.<br />
                    Vi sono vari tipi di cookie, alcuni per rendere più efficace l’uso del Sito, altri per abilitare determinate funzionalità.<br />
                    Analizzandoli in maniera particolareggiata i nostri cookie permettono di:
                </p>

                <p className="text-base font-normal text-black mb-4">
                    – memorizzare le preferenze inserite;<br />
                    – evitare di reinserire le stesse informazioni più volte durante la visita quali ad esempio nome utente e password;<br />
                    – analizzare l’utilizzo dei servizi e dei contenuti forniti da Villa Iris per ottimizzarne l’esperienza di navigazione e i servizi offerti.
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">Tipologie di Cookie utilizzati da Villa Iris</p>
                <p className="text-base font-normal text-black mb-4">
                    A seguire i vari tipi di cookie utilizzati da Villa Iris in funzione delle finalità d’uso
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">Cookie Tecnici</p>
                <p className="text-base font-normal text-black mb-4">
                    Questa tipologia di cookie permette il corretto funzionamento di alcune sezioni del Sito. Sono di due categorie, persistenti e di sessione:<br />
                    – persistenti: una volta chiuso il browser non vengono distrutti ma rimangono fino ad una data di scadenza preimpostata;<br />
                    – di sessione: vengono distrutti ogni volta che il browser viene chiuso;<br />
                    Questi cookie, inviati sempre dal nostro dominio, sono necessari a visualizzare correttamente il sito e in relazione ai servizi tecnici offerti, verranno quindi sempre utilizzati e inviati, a meno che l’utenza non modifichi le impostazioni nel proprio browser (inficiando così la visualizzazione delle pagine del sito).
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">Cookie analitici</p>
                <p className="text-base font-normal text-black mb-4">
                    I cookie in questa categoria vengono utilizzati per collezionare informazioni sull’uso del Sito. Villa Iris userà queste informazioni in merito ad analisi statistiche anonime al fine di migliorare l’utilizzo del Sito e per rendere i contenuti più interessanti e attinenti ai desideri dell’utenza. Questa tipologia di cookie raccoglie dati in forma anonimasull’attività dell’utenza e su come è arrivata sul Sito. I cookie analitici sono inviati dal Sito Stesso o da domini di terze parti.
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">Cookie di analisi di servizi di terze parti</p>
                <p className="text-base font-normal text-black mb-4">
                    Questi cookie sono utilizzati al fine di raccogliere informazioni sull’uso del Sito da parte degli utenti in forma anonima quali: pagine visitate, tempo di permanenza, origini del traffico di provenienza, provenienza geografica, età, genere e interessi ai fini di campagne di marketing. Questi cookie sono inviati da domini di terze parti esterni al Sito.
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">
                    Cookie per integrare prodotti e funzioni di software di terze parti
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Questa tipologia di cookie integra funzionalità sviluppate da terzi all’interno delle pagine del Sito come le icone e le preferenze espresse nei social network al fine di condivisione dei contenuti del sito o per l’uso di servizi software di terze parti (come i software per generare le mappe e ulteriori software che offrono servizi aggiuntivi). Questi cookie sono inviati da domini di terze parti e da siti partner che offrono le loro funzionalità tra le pagine del Sito.
                </p>


                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">
                    Cookie di profilazione
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Sono quei cookie necessari a creare profili utenti al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dall’utente all’interno delle pagine del Sito.
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Villa Iris, secondo la normativa vigente, non è tenuto a chiedere consenso per i cookie tecnici e di analytics, in quanto necessari a fornire i servizi richiesti.
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Per tutte le altre tipologie di cookie il consenso può essere espresso dall’Utente con una o più di una delle seguenti modalità:
                </p>

                <p className="text-base font-normal text-black mb-4">
                    – Mediante specifiche configurazioni del browser utilizzato o dei relativi programmi informatici utilizzati per navigare le pagine che compongono il Sito; <br />
                    – Mediante modifica delle impostazioni nell’uso dei servizi di terze parti. <br />
                    Entrambe queste soluzioni potrebbero impedire all’utente di utilizzare o visualizzare parti del Sito.
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">
                    Siti Web e servizi di terze parti
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Il Sito potrebbe contenere collegamenti ad altri siti Web che dispongono di una propria informativa sulla privacy che può essere diverse da quella adottata da Villa Iris e che che quindi non risponde di questi siti.
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Come disabilitare i cookie mediante configurazione del browser:
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">
                    Chrome
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Eseguire il Browser Chrome <br />
                    Fare click sul menù presente nella barra degli strumenti del browser a fianco della finestra di inserimento url per la navigazione <br />
                    Selezionare Impostazioni <br />
                    Fare clic su Mostra Impostazioni Avanzate <br />
                    Nella sezione “Privacy” fare clic su bottone “Impostazioni contenuti“ <br />
                    Nella sezione “Cookie” è possibile modificare le seguenti impostazioni relative ai cookie: <br />
                    Consentire il salvataggio dei dati in locale <br />
                    Modificare i dati locali solo fino alla chiusura del browser <br />
                    Impedire ai siti di impostare i cookie <br />
                    Bloccare i cookie di terze parti e i dati dei siti <br />
                    Gestire le eccezioni per alcuni siti internet <br />
                    Eliminazione di uno o tutti i cookie <br />
                    Per maggiori informazioni visita la pagina dedicata.
                </p>


                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">
                    Mozilla Firefox
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Eseguire il Browser Mozilla Firefox <br />
                    Fare click sul menù presente nella barra degli strumenti del browser a fianco della finestra di inserimento url per la navigazione <br />
                    Selezionare Opzioni <br />
                    Seleziona il pannello Privacy <br />
                    Fare clic su Mostra Impostazioni Avanzate <br />
                    Nella sezione “Privacy” fare clic su bottone “Impostazioni contenuti“ <br />
                    Nella sezione “Tracciamento” è possibile modificare le seguenti impostazioni relative ai cookie: <br />
                    Richiedi ai siti di non effettuare alcun tracciamento<br />
                    Comunica ai siti la disponibilità ad essere tracciato  <br />
                    Non comunicare alcuna preferenza relativa al tracciamento dei dati personali <br />
                    Dalla sezione “Cronologia” è possibile: <br />
                    Abilitando “Utilizza impostazioni personalizzate” selezionare di accettare i cookie di terze parti (sempre, dai siti più visitato o mai) e di conservarli per un periodo determinato (fino alla loro scadenza, alla chiusura di Firefox o di chiedere ogni volta <br />
                    Rimuovere i singoli cookie immagazzinati <br />
                    Per maggiori informazioni visita la pagina dedicata.
                </p>


                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">
                    Internet Explorer
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Eseguire il Browser Internet Explorer <br />
                    Fare click sul pulsante Strumenti e scegliere Opzioni Internet <br />
                    Fare click sulla scheda Privacy e nella sezione Impostazioni modificare il dispositivo di scorrimento in funzione dell’azione desiderata per i cookie: <br />
                    Bloccare tutti i cookie <br />
                    Consentire tutti i cookie <br />
                    Selezione dei siti da cui ottenere cookie: spostare il cursore in una posizione intermedia in modo da non bloccare o consentire tutti i cookie, premere quindi su Siti, nella casella Indirizzo Sito Web inserire un sito internet e quindi premere su Blocca o Consenti <br />
                    Per maggiori informazioni visita la pagina dedicata <br />
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">
                    Eseguire il Browser Safari
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Fare click su Safari, selezionare Preferenze e premere su Privacy <br />
                    Nella sezione Blocca Cookie specificare come Safari deve accettare i cookie dai siti internet. <br />
                    Per visionare quali siti hanno immagazzinato i cookie cliccare su Dettagli <br />
                    Per maggiori informazioni visita la pagina dedicata.
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">
                    Safari iOS (dispositivi mobile)
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Eseguire il Browser Safari iOS  <br />
                    Tocca su Impostazioni e poi Safari <br />
                    Tocca su Blocca Cookie e scegli tra le varie opzioni: “Mai”, “Di terze parti e inserzionisti” o “Sempre” <br />
                    Per cancellare tutti i cookie immagazzinati da Safari, tocca su Impostazioni, poi su Safari e infine su Cancella Cookie e dati <br />
                    Per maggiori informazioni visita la pagina dedicata.
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">
                    Opera
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Eseguire il Browser Opera <br />
                    Fare click sul Preferenze poi su Avanzate e infine su Cookie <br />
                    Selezionare una delle seguenti opzioni: <br />
                    Accetta tutti i cookie <br />
                    Accetta i cookie solo dal sito che si visita: i cookie di terze parti e che vengono inviati da un dominio diverso da quello che si sta visitando verranno rifiutati <br />
                    Non accettare mai i cookie: tutti i cookie non verranno mai salvati <br />
                    Per maggiori informazioni visita la pagina dedicata.
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0">Come disabilitare i cookie di servizi di terzi</p>
                <p className="text-base font-normal text-black mb-4">
                    <a href="https://myadcenter.google.com/personalizationoff?hl=it&sasb=true&ref=ad-settings" target="_blank" rel="noopener noreferrer" className="text-base font-medium text-blue-primary text-right no-underline">Servizi di Google</a><br />
                    <a href="https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0" target="_blank" rel="noopener noreferrer" className="text-base font-medium text-blue-primary text-right no-underline">Facebook</a><br />
                    <a href="https://help.twitter.com/en/rules-and-policies/x-cookies" target="_blank" rel="noopener noreferrer" className="text-base font-medium text-blue-primary text-right no-underline">Twitter</a>
                </p>

                <p className="text-base font-normal text-black mb-4">
                    Questa pagina è visibile, mediante link in calce in tutte le pagine del Sito ai sensi dell’art. 122 secondo comma del D.lgs. 196/2003 e a seguito delle modalità semplificate per l’informativa e l’acquisizione del consenso per l’uso dei cookie pubblicata sulla Gazzetta Ufficiale n.126 del 3 giugno 2014 e relativo registro dei provvedimenti n.229 dell’8 maggio 2014.
                </p>
            </div>
        </div>
    )
}

export default CookiePolicy;