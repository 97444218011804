import { BrowserRouter } from "react-router-dom";
import BaseRoutes from "./routes";

import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  return (
    <div className="overflow-x-hidden">
      <BrowserRouter>
      <Header/>
        <BaseRoutes />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
