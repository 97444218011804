import React from "react";

const WhistleBlowing = () => {
    return (


        <div className="container mx-auto flex flex-col items-center justify-between px-4 lg:px-6 mb-0 z-30">

            <div className="w-11/12 flex flex-col my-12">
                <h1 className="w-full mx-auto text-left text-blue-primary font-normal text-3xl lg:text-4xl mt-6 mb-8 lg:mx-0">
                    Whistleblowing
                </h1>

                <h1 className="w-full mx-auto text-left text-blue-primary font-bold text-2xl lg:text-3xl mt-6 mb-8 lg:mx-0">
                    CHE COS’È IL WHISTLEBLOWING
                </h1>

                <p className="text-base font-normal text-black mb-4">
                    In osservanza di quanto previsto dalla D.lgs. 10 marzo 2023, n. 24 (c.d. “Decreto Whistleblowing”)
                    Villa IRIS S.R.L. ha adottato un sistema di segnalazione dedicato che permette di gestire eventuali
                    segnalazioni di condotte illecite effettuate da dipendenti, collaboratori e utenti (whistleblower) in modo
                    completamente anonimo, come previsto del citato decreto.
                </p>

                <p className="text-base font-normal text-black mb-4">
                    Il processo è conforme alle novità normative introdotte dal Decreto Whistleblowing, di attuazione della
                    direttiva (UE) 2019/1937 del Parlamento europeo e del Consiglio del 23 ottobre 2019, riguardante la
                    protezione delle persone che segnalano violazioni del diritto dell’Unione e recante disposizioni riguardanti
                    la protezione delle persone che segnalano violazioni delle disposizioni normative nazionali.
                </p>

                <p className="w-full mx-auto text-left text-blue-primary font-semibold text-2xl lg:mt-0 lg:mx-0">
                    COME EFFETTUARE UNA SEGNALAZIONE
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Per effettuare una segnalazione, cliccare sul pulsante sottostante
                </p>

                <div className="bg-blue-300 hover:bg-blue-500 group block max-w-xs mr-auto px-4 py-2 rounded-3xl mb-6 cursor-pointer transition-all ease-in">
                    <a href="https://immobiliare.smartleaks.cloud/#/" target="_blank" className="text-blue-500 group-hover:text-white">Visualizza qui</a>
                </div>
                <p className="text-base font-normal text-black mb-2">
                    INFORMATIVA PRIVACY WHISTLEBLOWING
                </p>

                <div className="bg-blue-300 hover:bg-blue-500 group block max-w-xs mr-auto px-4 py-2 rounded-3xl mb-4 cursor-pointer transition-all ease-in">
                    <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/villairis/INF-SGDP.06+INFORMATIVA+PRIVACY+WHISTLEBLOWING.pdf" target="_blank" className="text-blue-500 group-hover:text-white">Visualizza qui</a>
                </div>

                <p className="w-full mx-auto text-left text-blue-primary font-semibold text-2xl lg:mt-0 lg:mx-0">
                    INDICAZIONI PER IL SEGNALANTE
                </p>
                <p className="text-base font-normal text-black mb-4">
                    Al seguente link potrete visionare il manuale utente
                </p>

                <div className="bg-blue-300 hover:bg-blue-500 group block max-w-xs mr-auto px-4 py-2 rounded-3xl mb-4 cursor-pointer transition-all ease-in">
                    <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/camerlengo/whistleblowing+(1).pdf" target="_blank" className="text-blue-500 group-hover:text-white">Visualizza qui</a>
                </div>

            </div>
        </div>
    )
}

export default WhistleBlowing;